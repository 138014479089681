/* eslint-disable react-hooks/exhaustive-deps */
// import { setUser } from 'modules/users/users.reducers';
import { MasterContext } from "contexts/MasterContext";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UserService from "../services/users";
import { OBJECT_TYPE } from "utils/enum";
import qs from "query-string";

const withAuth = (WrapperComponent: any) => {
  return function Auth(props: JSX.IntrinsicAttributes) {
    const history = useHistory();
    const location = useLocation();
    const { setAuth, setLocationAuth } = useContext(MasterContext);
    const PAGE_NOT_AUTH = ["login", "signup", "forgot-password"];

    const query: any = qs.parse(location.search);

    useEffect(() => {
      const didMount = async () => {
        let token = localStorage.getItem("token_auth");
        if (query && query.token) {
          localStorage.setItem("token_auth", query.token);
          token = query.token;
        }
        if (!token) {
          history.push("/login");
        } else {
          UserService.getUserAuth()
            .then((data: any) => {
              const { user } = data;
              if (user && user.object_type === OBJECT_TYPE.COMMUNITY) {
                const { profile } = user;
                if (profile.location && profile.location.country) {
                  setAuth(user);
                  setLocationAuth(profile.location);
                  if (
                    !PAGE_NOT_AUTH.some((url) =>
                      location.pathname.includes(url)
                    )
                  ) {
                    return;
                  } else history.push("/");
                  history.push("/");
                } else {
                  localStorage.removeItem("token_auth");
                  history.push("/login");
                }
              } else {
                localStorage.removeItem("token_auth");
                history.push("/login");
              }
            })
            .catch(() => {
              localStorage.removeItem("token_auth");
              history.push("/login");
            });
        }
      };
      didMount();
    }, [query.token]);

    return <WrapperComponent {...props} />;
  };
};

export default withAuth;
