import { request, parseErrorResponse } from "./request";
import { IRequestData } from "./s3-request";
import qs from "query-string";

const getNotifications = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/notifications`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`censor/notifications/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNotificationById = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/notifications/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createNotification = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`censor/notifications`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateNotification = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`censor/notifications/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deletedNotification = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`censor/notifications/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const NotificationService = {
  getNotifications,
  createNotification,
  updateNotification,
  deletedNotification,
  getNotificationById,
  search,
};

export default NotificationService;
