import { useState, createContext } from "react";
import CareerService from "services/careers";
import LocationService from "services/locations";
import SettingService from "services/settings";
import StockService from "services/stocks";
import UserService from "services/users";
import MenusService from "services/menus";
import { MENU_ROLE } from "utils/enum";
import NationService from "services/nations";
import ReligionService from "services/religions";
import CategoryFieldService from "services/category-field";
import { IObject } from "types";

type MasterContext = {
  auth?: IObject;
  users?: IObject;
  stocks?: any;
  getUsers?: any;
  getStocks?: any;
  setAuth?: any;
  notifyPending: any;
  getNotifyPending: any;
  objects: any;
  logo?: any;
  getObjects: any;
  getLogo?: any;
  languages?: any;
  getLanguages?: any;
  careers?: any;
  getCareers?: any;
  locations?: any;
  getLocations?: any;
  menus?: any;
  getMenus?: any;
  getNations?: any;
  getReligions?: any;
  nations?: any;
  religions?: any;
  categoryFields?: any;
  getCategoryFields?: any;
  locationAuth: IObject;
  setLocationAuth: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [auth, setAuth] = useState(null);
  const [objects, setObjects] = useState([]);
  const [logo, setLogo] = useState({});
  const [notifyPending] = useState({});
  const [languages, setLanguages] = useState([]);
  const [careers, setCareers] = useState([]);
  const [nations, setNations] = useState([]);
  const [religions, setReligions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [menus, setMenus] = useState([]);
  const [categoryFields, setCategoryFields] = useState([]);
  const [locationAuth, setLocationAuth] = useState([]);

  const getStocks = () => {
    StockService.getAllCryptos().then((res: any) => {
      if (res && res.cryptos) {
        const _stocks = res.cryptos.map((s) => ({
          ...s,
          fullName: `${s.name} (${String(s.symbol).toUpperCase()})`,
        }));
        setStocks(_stocks);
      }
    });
  };

  const getNotifyPending = () => {
    // SystemService.getCountPending().then((res: any) => {
    //   if (res && res.data) setNotifyPending(res.data);
    // });
  };

  const getObjects = () => {
    SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setObjects(res.setting.value);
      }
    });
  };

  const getLogo = () => {
    SettingService.getSettingsByName({
      params: {
        name: "logos",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setLogo(res.setting.value);
      }
    });
  };

  const getCareers = () => {
    CareerService.getAll().then((res: any) => {
      setCareers(res || []);
    });
  };

  const getCategoryFields = () => {
    CategoryFieldService.getAll().then((res: any) => {
      setCategoryFields(res || []);
    });
  };

  const getNations = () => {
    NationService.getAll().then((res: any) => {
      setNations(res || []);
    });
  };

  const getReligions = () => {
    ReligionService.getAll().then((res: any) => {
      setReligions(res || []);
    });
  };

  const getLocations = () => {
    LocationService.getAll().then((res: any) => {
      setLocations(res || []);
    });
  };

  const getLanguages = () => {
    SettingService.getSettingsByName({
      params: {
        name: "languages",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setLanguages(res.setting.value);
      }
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        blocked: false,
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  const getMenus = () => {
    MenusService.getTreeMenu({ params: { role: MENU_ROLE.CENSOR } }).then(
      (res: any) => {
        setMenus(res);
      }
    );
  };

  return (
    <MasterContext.Provider
      value={{
        careers,
        auth,
        users,
        stocks,
        notifyPending,
        objects,
        logo,
        languages,
        locations,
        menus,
        nations,
        religions,
        categoryFields,
        locationAuth,
        getLocations,
        getCareers,
        getLanguages,
        getObjects,
        getLogo,
        getStocks,
        getUsers,
        setAuth,
        getNotifyPending,
        getMenus,
        getNations,
        getReligions,
        getCategoryFields,
        setLocationAuth,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
