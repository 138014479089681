import { Button } from "primereact";
import { formatNumber } from "./common";
import { IObject } from "types";
import { LOCATION_TYPES } from "./enum";

/* eslint-disable react/jsx-no-target-blank */
export const renderAmount = (amount) => {
  const _dau = amount >= 0 ? "+" : "-";
  const _class =
    amount >= 0
      ? "p-button-success p-button-text"
      : "p-button-danger p-button-text";
  return (
    <Button className={`font-bold ${_class} p-0`}>
      {_dau}
      {formatNumber(Math.abs(amount))}
    </Button>
  );
};

export const renderHash = (hash = "") => {
  const _hash = `${
    process.env.REACT_APP_VZX_SCAN || "https://block.Vdiarybook.com"
  }/tx/${hash}`;
  return (
    <a href={_hash} target={"_blank"}>
      {hash.slice(0, 5)}...{hash.slice(hash.length - 5, hash.length)}
    </a>
  );
};

export const renderAddress = (address: IObject, _locations = []) => {
  let countryName;
  let provinceName;
  let districtName;
  let wardName;
  let ids: any = [];
  if (!address) return "";

  if (address.country) ids.push(address?.country);
  if (address.province) ids.push(address?.province);
  if (address.district) ids.push(address?.district);
  if (address.ward) ids.push(address?.ward);
  const locations = _locations.filter((item) => ids.includes(item._id));
  const country = locations.find((l) => l.type === LOCATION_TYPES.COUNTRY);
  if (country) countryName = country.name;
  const province = locations.find((l) => l.type === LOCATION_TYPES.PROVINCE);
  if (province) provinceName = province.name;
  const district = locations.find((l) => l.type === LOCATION_TYPES.DISTRICT);
  if (district) districtName = district.name;
  const ward = locations.find((l) => l.type === LOCATION_TYPES.WARD);
  if (ward) wardName = ward.name;
  return `${address?.detail ? `${address?.detail}, ` : ""}${
    wardName ? `${wardName}, ` : ""
  }${districtName ? `${districtName}, ` : ""}${
    provinceName ? `${provinceName}, ` : ""
  }${countryName ? `${countryName}` : ""}`;
};

export const renderLocationById = (addressId: string, _locations = []) => {
  const location = _locations.find((item) => item._id === addressId);
  if (location) return location.name;
  else return "";
};
