import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact";
import { useContext } from "react";

const View = ({ value, setValue }) => {
  const { users } = useContext(MasterContext);

  return (
    <Dropdown
      defaultValue={value}
      value={value}
      options={users}
      onChange={(e) => setValue(e.value)}
      optionLabel="email"
      filterBy="email"
      optionValue="_id"
      filter
      showClear
      placeholder="Select user"
    />
  );
};

export default View;
