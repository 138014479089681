/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import BannerService from "services/banners";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { nanoid } from "nanoid";
import { Calendar } from "primereact/calendar";
import { InputNumber, Panel, MultiSelect } from "primereact";
import CategoryFilterDetails from "components/v-filter";
import { COMMON_STATUS } from "utils/enum";
import { MasterContext } from "contexts/MasterContext";

const emptyData = {
  user_id: false,
  is_facebook: false,
  is_youtube: false,
  is_images: false,
  facebook: "",
  youtube: "",
  positions: [],
  images: [],
  start_date: new Date(),
  end_date: new Date(),
  countdown: "",
  order: 0,
  video: "",
  category_filter_id: null,
  status: COMMON_STATUS.ENABLE,
};

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    bannerPositions,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);
  const { auth } = useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : new Date(),
        end_date: data.end_date ? new Date(data.end_date) : new Date(),
        countdown: new Date(data.countdown),
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const onInputCheckChange = (val, name) => {
    setDetails({
      ...details,
      [name]: val,
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      category = await refDetailCategory.current.submit();
      getCategoryFilters();
      if (data) {
        await BannerService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else {
        await BannerService.create({
          body: {
            ...details,
            user_id: auth._id,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const moveUp = (index: number) => {
    const _images = details.images;
    const _before = details.images[index - 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index - 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const moveDown = (index: number) => {
    const _images = details.images;
    const _before = details.images[index + 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index + 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const handleSubIndex = (index) => {
    setDetails((state) => ({
      ...state,
      images: state.images.filter((i) => i.id !== index),
    }));
  };

  const changeLinkItemImages = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, link: e.target.value } : item
      ),
    }));
  };

  const handleImageChange = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, src: e } : item
      ),
    }));
  };

  const handleAdd = () => {
    const added = [...details.images, { id: nanoid(), src: "", link: "" }];
    setDetails((state) => ({ ...state, images: added }));
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Banners</h4>
          </div>

          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="order">Status</label>
              <Dropdown
                defaultValue={details.status}
                value={details.status}
                options={[
                  {
                    label: "ENABLE",
                    value: "enable",
                  },
                  {
                    label: "DISABLE",
                    value: "disable",
                  },
                ]}
                onChange={(e) => onChange("status", e.value)}
                optionLabel="label"
                placeholder="Select status"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="order">Order</label>
              <InputNumber
                value={details.order}
                onChange={(e) => onChange("order", e.value)}
              />
            </div>

            <div className="field col-12 md:col-12">
              <label htmlFor="status">Banner Position</label>
              <MultiSelect
                value={details.positions}
                options={bannerPositions.map((item) => ({
                  label: item.name,
                  value: item.key,
                }))}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Select banner position"
                onChange={(e) => {
                  onInputCheckChange(e.value, "positions");
                }}
              />
            </div>
          </div>

          <div className="grid">
            <div className="field col-12">
              <label htmlFor="status">Filter</label>
              <Dropdown
                filter
                showClear
                value={details.category_filter_id}
                options={categoryFilters.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                disabled
                optionLabel="label"
                optionValue="value"
                placeholder="Select filter"
                onChange={(e) =>
                  onInputCheckChange(e.value, "category_filter_id")
                }
              />
            </div>
          </div>

          <div className="grid">
            <div className="field col-12 md:col-4">
              <label htmlFor="start-date">Start Date</label>
              <Calendar
                id="start-date"
                showIcon
                showTime
                placeholder="Start date"
                value={details.start_date}
                onChange={(e) => onChange("start_date", e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="end-date">End Date</label>
              <Calendar
                id="end-date"
                showIcon
                showTime
                placeholder="End date"
                value={details.end_date}
                onChange={(e) => onChange("end_date", e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="countdown">Countdown</label>
              <Calendar
                id="countdown"
                showIcon
                showTime
                placeholder="Countdown"
                value={details.countdown}
                onChange={(e) => onChange("countdown", e.value)}
              />
            </div>
          </div>
          <div className="grid">
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={details.facebook}
                  onChange={(e) => onChange("facebook", e.target.value)}
                />
                <label htmlFor="id">Facebook</label>
              </span>
            </div>

            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={details.youtube}
                  onChange={(e) => onChange("youtube", e.target.value)}
                />
                <label htmlFor="id">Youtube</label>
              </span>
            </div>
          </div>
          <div>
            <h4>Video</h4>
            <VUpload
              fileType="video"
              urlFile={details.video}
              setUrlFile={(url) => onChange("video", url)}
            />
          </div>
          <div className="pb-3">
            <h4>Images</h4>
          </div>
          {details.images.map((item, index) => {
            return (
              <div className="grid" key={item.id}>
                <div className="col-12">
                  <VUpload
                    urlFile={item.src}
                    setUrlFile={(e) => handleImageChange(e, item.id)}
                  />
                </div>

                <div className="col-12 lg:col-10">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.link}
                      onChange={(e) => changeLinkItemImages(e, item.id)}
                    />
                    <label htmlFor="link">Link</label>
                  </span>
                </div>

                <div className="flex field col-12 md:col-2 md:justify-content-evenly">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.id)}
                  />
                  {index === 0 ? (
                    <Button
                      icon="bx bx-chevron-down"
                      className="p-button-rounded p-button-warning"
                      aria-label="Cancel"
                      onClick={(_) => moveDown(index)}
                    />
                  ) : index === details.images.length - 1 ? (
                    <Button
                      icon="bx bx-chevron-up"
                      className="p-button-rounded p-button-primary"
                      aria-label="Cancel"
                      onClick={(_) => moveUp(index)}
                    />
                  ) : (
                    <>
                      <Button
                        icon="bx bx-chevron-up"
                        className="p-button-rounded p-button-primary"
                        aria-label="Cancel"
                        onClick={(_) => moveUp(index)}
                      />
                      <Button
                        icon="bx bx-chevron-down"
                        className="p-button-rounded p-button-warning"
                        aria-label="Cancel"
                        onClick={(_) => moveDown(index)}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />

          <div className="field col-12">
            <Panel
              header="Filter"
              toggleable
              className="my-2"
              collapsed={false}
            >
              <div className="grid">
                <div className="field col-12">
                  <CategoryFilterDetails
                    ref={refDetailCategory}
                    isCensorLocation
                  />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
