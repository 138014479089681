import { request, parseErrorResponse } from "./request";

const login = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("/censor/login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
};

export default AuthService;
