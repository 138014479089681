/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { getUrlImage } from "utils/common";
import { forwardRef } from "react";
import { Image } from "primereact/image";
import { MasterContext } from "contexts/MasterContext";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";

const Details = (props, ref) => {
  const { data, onCancel } = props;
  const { locations } = useContext(MasterContext);

  const emptyData = {
    status: "pending_verified",
    note: "",
    object: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChangeEditor = (e, name) => {
    setDetails((prev) => ({
      ...prev,
      [name]: e.htmlValue,
    }));
  };

  const submit = async () => {
    onCancel();
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|mov)$/.test(url);
  };

  const srcIsVideo = (src: any) => {
    const type = src.split(".")[src.split(".").length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  };

  const checkIsLocation = (id: string) => {
    const country = locations.find((el) => String(el._id) === id);
    if (country) {
      return country;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="grid">
        {/* <div className="field col-12"> */}
        {Object.keys(data?.value_kyc || {})?.map((value, i) => {
          if (srcIsVideo(data.value_kyc[value])) {
            return (
              <div key={i} className="field col-12 md:col-12">
                <span className="field col-12 md:col-6">- {value}:</span>
                <div style={{ maxWidth: "500px" }}>
                  <video
                    src={data.value_kyc[value]}
                    width="100%"
                    height="auto"
                    autoPlay
                    loop
                  >
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
            );
          } else if (!isImage(data.value_kyc[value])) {
            return (
              <>
                <div key={i} className="field col-12 md:col-6">
                  <label htmlFor={value}>{value}</label>
                  <InputText
                    id={value}
                    value={
                      checkIsLocation(data.value_kyc[value])?.name ||
                      data.value_kyc[value]
                    }
                    disabled
                  />
                </div>
              </>
            );
          } else {
            let imgs = data?.value_kyc[value];
            if (!Array.isArray(imgs)) {
              imgs = [imgs];
            }

            return (
              <div key={i} className="grid mt-3">
                {imgs.map((at, index) => (
                  <div key={index} className="">
                    <span className="font-semibold block mb-3">- {value}:</span>
                    <Image
                      src={getUrlImage(at)}
                      width="200"
                      height="auto"
                      preview
                    />
                  </div>
                ))}
              </div>
            );
          }
        })}
        {/* </div> */}
      </div>

      <div className="grid">
        <div className="field col-12">
          {Object.keys(data?.value_verify || {})?.map((value, i) => {
            if (srcIsVideo(data.value_verify[value])) {
              return (
                <div key={i} className="grid mt-3 flex-column">
                  <span className="font-semibold block mb-3">- {value}:</span>
                  <div style={{ maxWidth: "500px" }}>
                    <video
                      src={getUrlImage(data.value_verify[value])}
                      width="100%"
                      height="auto"
                      autoPlay
                      loop
                    >
                      Your browser does not support HTML video.
                    </video>
                  </div>
                </div>
              );
            } else if (!isImage(data.value_verify[value])) {
              return (
                <div key={i} className="grid mt-3">
                  <span className="font-semibold">- {value}:</span>
                  <div className="ml-4">{data.value_verify[value]}</div>
                </div>
              );
            } else {
              let imgs = data?.value_verify[value];
              if (!Array.isArray(imgs)) {
                imgs = [imgs];
              }

              return (
                <div key={i} className="grid mt-3">
                  {imgs.map((at, index) => (
                    <div key={index} className="">
                      <span className="font-semibold block mb-3">
                        - {value}:
                      </span>
                      <Image
                        src={getUrlImage(at)}
                        width="200"
                        height="auto"
                        preview
                      />
                    </div>
                  ))}
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Status : {details.status}</label>
        </div>
        <div className="field col-12">
          <label htmlFor="description">Note</label>
          <Editor
            id="note"
            className="h-12rem"
            value={details.note}
            onTextChange={(e) => onChangeEditor(e, "note")}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(Details);
