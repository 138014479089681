/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useContext,
} from "react";
import { InputText, Panel } from "primereact";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import NotificationService from "services/notifications";
import CategoryFilterDetails from "components/v-filter";
import VUpload from "components/v-upload";
import { MasterContext } from "contexts/MasterContext";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const { auth } = useContext(MasterContext);

  const emptyData = {
    from_id: auth._id,
    data: {
      title: "",
      image: "",
      url: "",
      body: "",
      extra: {},
    },
    category_filter_id: null,
  };
  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (name, value, extra = null) => {
    let _details = { ...details };
    if (extra) {
      _details[extra][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await NotificationService.updateNotification({
          params: {
            id: data._id,
          },
          body: {
            ...details,

            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else {
        await NotificationService.createNotification({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Notifications saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          disabled
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={details.data.title}
          onChange={(e) => onChange("title", e.target.value, "data")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.data.url}
          onChange={(e) => onChange("url", e.target.value, "data")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <InputText
          id="content"
          value={details.data.body}
          onChange={(e) => onChange("body", e.target.value, "data")}
          required
          autoFocus
        />
      </div>
      <div className="col-12">
        <VUpload
          urlFile={details.data.image}
          setUrlFile={(e) => onChange("image", getUrlImage(e), "data")}
        />
      </div>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} isCensorLocation />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
