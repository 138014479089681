import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact";
import { useContext, useEffect, useState } from "react";

type IAddress = {
  country?: string;
  province?: string;
  district?: string;
  ward?: string;
  detail?: string;
};

type IProps = {
  address: IAddress;
  setAddress: (data: IAddress) => void;
  isDisabled?: boolean;
};

const View = (props: IProps) => {
  const { address, isDisabled, setAddress } = props;
  const [countries, setCountries] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);

  const { locations, locationAuth } = useContext(MasterContext);
  const setFilterCities = async (key: string, e: any) => {
    const code = e.target.value;
    if (key === "country") {
      if (code) {
        const _provices = locations?.filter((pr: any) => pr.parent === code);
        if (_provices) {
          setProvinces(_provices);
          setAddress({
            country: code,
            province: null,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            country: null,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          country: null,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "province") {
      if (code) {
        const _districts = locations?.filter((pr: any) => pr.parent === code);
        if (_districts) {
          setDistricts(_districts);
          setAddress({
            ...address,
            province: code,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            ...address,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          ...address,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "district") {
      if (code) {
        const _wards = locations?.filter((pr: any) => pr.parent === code);
        setWards(_wards);
        setAddress({
          ...address,
          district: code,
          ward: null,
        });
      } else {
        setWards([]);
        setAddress({
          ...address,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "ward") {
      if (code) {
        setAddress({
          ...address,
          ward: code,
        });
      } else {
        setAddress({
          ...address,
          ward: null,
        });
      }
    }
  };

  useEffect(() => {
    setCountries(locations?.filter((a) => a.type === "country"));

    if (address && address.country) {
      const _provinces = locations?.filter(
        (pro: any) => pro.parent === address.country
      );
      setProvinces(_provinces);
      if (address.province) {
        const _districts: any = locations?.filter(
          (pro: any) => pro.parent === address.province
        );
        setDistricts(_districts);
      }
      if (address.district) {
        const _wards: any = locations?.filter(
          (pro: any) => pro.parent === address.district
        );
        setWards(_wards);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, address]);
  useEffect(() => {
    if (locationAuth && locationAuth.country) {
      const _provinces = locations?.filter(
        (pro: any) => pro.parent === locationAuth.country
      );
      setProvinces(_provinces);
      if (locationAuth.province) {
        const _districts: any = locations?.filter(
          (pro: any) => pro.parent === locationAuth.province
        );
        setDistricts(_districts);
      }
      if (locationAuth.district) {
        const _wards: any = locations?.filter(
          (pro: any) => pro.parent === locationAuth.district
        );
        setWards(_wards);
      }
      setAddress(locationAuth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAuth]);

  return (
    <div className="align-items-center text-nowrap container">
      <div className="grid">
        <div className="field col-12 md:col-6">
          <Dropdown
            disabled={locationAuth?.country || isDisabled}
            placeholder={"Quốc gia"}
            value={locationAuth?.country || address?.country}
            options={countries?.map((el: any) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })}
            onChange={(e: any) => setFilterCities("country", e)}
            filter
            showClear
          />
        </div>
        <div className="field col-12 md:col-6">
          <Dropdown
            disabled={locationAuth?.province || isDisabled}
            placeholder={"Tỉnh/Thành phố"}
            value={locationAuth?.province || address?.province}
            options={provinces?.map((el: any) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })}
            onChange={(e: any) => setFilterCities("province", e)}
            filter
            showClear
          />
        </div>
        <div className="field col-12 md:col-6">
          <Dropdown
            disabled={locationAuth?.district || isDisabled}
            placeholder={"Quận/Huyện"}
            value={locationAuth?.district || address?.district}
            options={districts?.map((el: any) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })}
            onChange={(e: any) => setFilterCities("district", e)}
            filter
            showClear
          />
        </div>
        <div className="field col-12 md:col-6">
          <Dropdown
            disabled={locationAuth?.ward || isDisabled}
            placeholder={"Xã/Phường"}
            value={locationAuth?.ward || address?.ward}
            options={wards?.map((el: any) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            })}
            onChange={(e: any) => setFilterCities("ward", e)}
            filter
            showClear
          />
        </div>
      </div>
    </div>
  );
};

export default View;
