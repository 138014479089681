export const IDO_INFO = [
  {
    key: "token",
    value: "Token",
    type: "text",
  },
  {
    key: "network",
    value: "Network",
    type: "text",
  },
  {
    key: "price",
    value: "IDO Token Price",
    type: "text",
  },
  {
    key: "pool",
    value: "Total IDO Pool size",
    type: "text",
  },
  {
    key: "type",
    value: "IDO type",
    type: "text",
  },
  {
    key: "vesting",
    value: "Vesting",
    type: "text",
  },
];

export const KEEP_TIME = [
  {
    key: "ido_open",
    value: "IBO open",
    type: "datetime-local",
  },
  {
    key: "ido_close",
    value: "IBO close",
    type: "datetime-local",
  },
];

export const SOCIAL = [
  "Telegram",
  "Twitter",
  "Facebook",
  "Document",
  "Youtobe",
];

export const REWARD = {
  stocks: [],
  vouchers: [],
  codes: [],
  tickets: [],
};

export const REGIONS = [
  {
    code: 1,
    name: "Tây Bắc Bộ",
    provices: [
      "Điện Biên",
      "Hoà Bình",
      "Lai Châu",
      "Lào Cai",
      "Sơn La",
      "Yên Bái",
    ],
  },
  {
    code: 2,
    name: "Đông Bắc Bộ",
    provices: [
      "Bắc Giang",
      "Bắc Kạn",
      "Cao Bằng",
      "Hà Giang",
      "Lạng Sơn",
      "Phú Thọ",
      "Quảng Ninh",
      "Thái Nguyên",
      "Tuyên Quang",
    ],
  },
  {
    code: 3,
    name: "Đồng bằng sông Hồng",
    provices: [
      "Bắc Ninh",
      "Hà Nam",
      "Hà Nội",
      "Hải Dương",
      "Hải Phòng",
      "Hưng Yên",
      "Nam Định",
      "Ninh Bình",
      "Thái Bình",
      "Vĩnh Phúc",
    ],
  },
  {
    code: 4,
    name: "Bắc Trung Bộ",
    provices: [
      "Thanh Hóa",
      "Nghệ An",
      "Hà Tĩnh",
      "Quảng Bình",
      "Quảng Trị",
      "Thừa Thiên Huế",
    ],
  },
  {
    code: 5,
    name: "Nam Trung Bộ",
    provices: [
      "Đà Nẵng",
      "Quảng Nam",
      "Quảng Ngãi",
      "Bình Định",
      "Phú Yên",
      "Khánh Hòa",
      "Ninh Thuận",
      "Bình Thuận",
    ],
  },
  {
    code: 6,
    name: "Tây Nguyên",
    provices: ["Kon Tum", "Gia Lai", "Đắk Lắk", "Đắk Nông", "Lâm Đồng"],
  },
  {
    code: 7,
    name: "Đông Nam Bộ",
    provices: [
      "Bình Phước",
      "Bình Dương",
      "Đồng Nai",
      "Tây Ninh",
      "Vũng Tàu",
      "Thành phố Hồ Chí Minh",
    ],
  },
  {
    code: 8,
    name: "Tây Nam Bộ",
    provices: [
      "Long An",
      "Đồng Tháp",
      "Tiền Giang",
      "An Giang",
      "Bến Tre",
      "Vĩnh Long",
      "Trà Vinh",
      "Hậu Giang",
      "Kiên Giang",
      "Sóc Trăng",
      "Bạc Liêu",
      "Cà Mau",
      "Cần Thơ",
    ],
  },
];

export const LEVELS = [
  {
    name: "F1",
    value: "f1",
  },
  {
    name: "F2",
    value: "f2",
  },
  {
    name: "F3",
    value: "f3",
  },
  {
    name: "F4",
    value: "f4",
  },
  {
    name: "F5",
    value: "f5",
  },
];

export const ROLE_CLIENT = [
  "notifications.read",
  "notifications.create",
  "notifications.update",

  "advertisements.read",
  "advertisements.create",
  "advertisements.update",

  "events.create",
  "events.read",
  "events.update",
  "events.delete",
  "events.trust",
  "events.ads_trust",

  "projects.trust",
  "projects.ads_trust",
  "profile.update_cover",
];

export const ROLE_ADMIN = [
  "users.create",
  "users.read",
  "users.update",
  "users.delete",

  "kycs.create",
  "kycs.read",
  "kycs.update",
  "kycs.delete",

  "transactions.create",
  "transactions.read",
  "transactions.update",
  "transactions.delete",

  "cryptos.create",
  "cryptos.read",
  "cryptos.update",
  "cryptos.delete",

  "products.create",
  "products.read",
  "products.update",
  "products.delete",

  "allotments.create",
  "allotments.read",
  "allotments.update",
  "allotments.delete",

  "systems.create",
  "systems.read",
  "systems.update",
  "systems.delete",

  "projects.create",
  "projects.read",
  "projects.update",
  "projects.delete",
  "projects.trust",
  "projects.ads_trust",

  "payments.create",
  "payments.read",
  "payments.update",
  "payments.delete",

  "tasks.create",
  "tasks.read",
  "tasks.update",
  "tasks.delete",

  "notifications.create",
  "notifications.read",
  "notifications.update",
  "notifications.delete",

  "advertisements.create",
  "advertisements.read",
  "advertisements.update",
  "advertisements.delete",

  "your-estock.create",
  "your-estock.read",
  "your-estock.update",
  "your-estock.delete",

  "events.create",
  "events.read",
  "events.update",
  "events.delete",
  "events.trust",
  "events.ads_trust",

  "levels.create",
  "levels.read",
  "levels.update",
  "levels.delete",

  "campaigns.create",
  "campaigns.read",
  "campaigns.update",
  "campaigns.delete",

  "supports.create",
  "supports.read",
  "supports.update",
  "supports.delete",
];

export const CATEGORY_GROUP_KEY = {
  MENU: "menu_group",
  PAGE_BACKGROUND: "page_background",
  BANNER_PAGE: "banner_page",
};

export const FORM_TEMPLATE_KEY = [
  {
    name: "Support",
    value: "support",
  },
  {
    name: "Survey",
    value: "survey",
  },
  {
    name: "Profile Setting",
    value: "profile_setting",
  },
  {
    name: "Kyc",
    value: "kyc",
  },
  {
    name: "Verify",
    value: "verify",
  },
];

export const ICONS = {
  PREFIX: "pi ",
  KEYS: [
    "pi-align-center",
    "pi-align-justify",
    "pi-align-left",
    "pi-align-right",
    "pi-amazon",
    "pi-android",
    "pi-angle-double-down",
    "pi-angle-double-left",
    "pi-angle-double-right",
    "pi-angle-double-up",
    "pi-angle-down",
    "pi-angle-left",
    "pi-angle-right",
    "pi-angle-up",
    "pi-apple",
    "pi-arrow-circle-down",
    "pi-arrow-circle-left",
    "pi-arrow-circle-right",
    "pi-arrow-circle-up",
    "pi-arrow-down",
    "pi-arrow-down-left",
    "pi-arrow-down-right",
    "pi-arrow-left",
    "pi-arrow-right",
    "pi-arrow-right-arrow-left",
    "pi-arrow-up",
    "pi-arrow-up-left",
    "pi-arrow-up-right",
    "pi-arrows-alt",
    "pi-arrows-h",
    "pi-arrows-v",
    "pi-at",
    "pi-backward",
    "pi-ban",
    "pi-bars",
    "pi-bell",
    "pi-bitcoin",
    "pi-bolt",
    "pi-book",
    "pi-bookmark",
    "pi-bookmark-fill",
    "pi-box",
    "pi-briefcase",
    "pi-building",
    "pi-calculator",
    "pi-calendar",
    "pi-calendar-minus",
    "pi-calendar-plus",
    "pi-calendar-times",
    "pi-camera",
    "pi-car",
    "pi-caret-down",
    "pi-caret-left",
    "pi-caret-right",
    "pi-caret-up",
    "pi-cart-plus",
    "pi-chart-bar",
    "pi-chart-line",
    "pi-chart-pie",
    "pi-check",
    "pi-check-circle",
    "pi-check-square",
    "pi-chevron-circle-down",
    "pi-chevron-circle-left",
    "pi-chevron-circle-right",
    "pi-chevron-circle-up",
    "pi-chevron-down",
    "pi-chevron-left",
    "pi-chevron-right",
    "pi-chevron-up",
    "pi-circle",
    "pi-circle-fill",
    "pi-clock",
    "pi-clone",
    "pi-cloud",
    "pi-cloud-download",
    "pi-cloud-upload",
    "pi-code",
    "pi-cog",
    "pi-comment",
    "pi-comments",
    "pi-compass",
    "pi-copy",
    "pi-credit-card",
    "pi-database",
    "pi-delete-left",
    "pi-desktop",
    "pi-directions",
    "pi-directions-alt",
    "pi-discord",
    "pi-dollar",
    "pi-download",
    "pi-eject",
    "pi-ellipsis-h",
    "pi-ellipsis-v",
    "pi-envelope",
    "pi-eraser",
    "pi-euro",
    "pi-exclamation-circle",
    "pi-exclamation-triangle",
    "pi-external-link",
    "pi-eye",
    "pi-eye-slash",
    "pi-facebook",
    "pi-fast-backward",
    "pi-fast-forward",
    "pi-file",
    "pi-file-edit",
    "pi-file-excel",
    "pi-file-export",
    "pi-file-import",
    "pi-file-pdf",
    "pi-file-word",
    "pi-filter",
    "pi-filter-fill",
    "pi-filter-slash",
    "pi-flag",
    "pi-flag-fill",
    "pi-folder",
    "pi-folder-open",
    "pi-forward",
    "pi-gift",
    "pi-github",
    "pi-globe",
    "pi-google",
    "pi-hashtag",
    "pi-heart",
    "pi-heart-fill",
    "pi-history",
    "pi-home",
    "pi-hourglass",
    "pi-id-card",
    "pi-image",
    "pi-images",
    "pi-inbox",
    "pi-info",
    "pi-info-circle",
    "pi-instagram",
    "pi-key",
    "pi-language",
    "pi-link",
    "pi-linkedin",
    "pi-list",
    "pi-lock",
    "pi-lock-open",
    "pi-map",
    "pi-map-marker",
    "pi-megaphone",
    "pi-microphone",
    "pi-microsoft",
    "pi-minus",
    "pi-minus-circle",
    "pi-mobile",
    "pi-money-bill",
    "pi-moon",
    "pi-palette",
    "pi-paperclip",
    "pi-pause",
    "pi-paypal",
    "pi-pencil",
    "pi-percentage",
    "pi-phone",
    "pi-play",
    "pi-plus",
    "pi-plus-circle",
    "pi-pound",
    "pi-power-off",
    "pi-prime",
    "pi-print",
    "pi-qrcode",
    "pi-question",
    "pi-question-circle",
    "pi-reddit",
    "pi-refresh",
    "pi-replay",
    "pi-reply",
    "pi-save",
    "pi-search",
    "pi-search-minus",
    "pi-search-plus",
    "pi-send",
    "pi-server",
    "pi-share-alt",
    "pi-shield",
    "pi-shopping-bag",
    "pi-shopping-cart",
    "pi-sign-in",
    "pi-sign-out",
    "pi-sitemap",
    "pi-slack",
    "pi-sliders-h",
    "pi-sliders-v",
    "pi-sort",
    "pi-sort-alpha-down",
    "pi-sort-alpha-down-alt",
    "pi-sort-alpha-up",
    "pi-sort-alpha-up-alt",
    "pi-sort-alt",
    "pi-sort-alt-slash",
    "pi-sort-amount-down",
    "pi-sort-amount-down-alt",
    "pi-sort-amount-up",
    "pi-sort-amount-up-alt",
    "pi-sort-down",
    "pi-sort-numeric-down",
    "pi-sort-numeric-down-alt",
    "pi-sort-numeric-up",
    "pi-sort-numeric-up-alt",
    "pi-sort-up",
    "pi-spinner",
    "pi-star",
    "pi-star-fill",
    "pi-step-backward",
    "pi-step-backward-alt",
    "pi-step-forward",
    "pi-step-forward-alt",
    "pi-stop",
    "pi-stop-circle",
    "pi-stopwatch",
    "pi-sun",
    "pi-sync",
    "pi-table",
    "pi-tablet",
    "pi-tag",
    "pi-tags",
    "pi-telegram",
    "pi-th-large",
    "pi-thumbs-down",
    "pi-thumbs-down-fill",
    "pi-thumbs-up",
    "pi-thumbs-up-fill",
    "pi-ticket",
    "pi-times",
    "pi-times-circle",
    "pi-trash",
    "pi-truck",
    "pi-twitter",
    "pi-undo",
    "pi-unlock",
    "pi-upload",
    "pi-user",
    "pi-user-edit",
    "pi-user-minus",
    "pi-user-plus",
    "pi-users",
    "pi-verified",
    "pi-video",
    "pi-vimeo",
    "pi-volume-down",
    "pi-volume-off",
    "pi-volume-up",
    "pi-wallet",
    "pi-whatsapp",
    "pi-wifi",
    "pi-window-maximize",
    "pi-window-minimize",
    "pi-wrench",
    "pi-youtube",
  ],
  SIZE: " text-2xl",
};

export const selectEmpty = { id: "-1", label: "Select", key: "-1" };
