/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import PostService from "services/posts";
import { Image } from "primereact/image";

const emptyData = null;

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      await PostService.update({
        params: {
          id: data._id,
        },
        body: {
          admin_status: details.admin_status,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Post Detail</h4>
          </div>

          <div className="grid">
            <div className="field col-12">
              <Dropdown
                defaultValue={details?.admin_status}
                value={details?.admin_status}
                options={[
                  {
                    label: "ENABLE",
                    value: "enable",
                  },
                  {
                    label: "DISABLE",
                    value: "disable",
                  },
                ]}
                onChange={(e) => onChange("admin_status", e.value)}
                optionLabel="label"
                placeholder="Select status"
              />
            </div>
            {details && (
              <div className="field col-12">
                <div>
                  {" "}
                  <label htmlFor="order">Status : </label>
                  <span>{details.post_status}</span>
                  <hr />
                </div>
                <div>
                  {" "}
                  <label htmlFor="order">Privacy : </label>
                  <span>{details.post_privacy}</span>
                  <hr />
                </div>
                <div>
                  {" "}
                  <label htmlFor="order">Title : </label>
                  <span>{details.post_title}</span>
                  <hr />
                </div>
                <div>
                  {" "}
                  <label htmlFor="order">Content : </label>
                  <span>{details.post_content}</span>
                  <hr />
                </div>
                <div>
                  <label htmlFor="order">File : </label>
                  <div>
                    {String(details.file_main?.post_file_type || "").includes(
                      "video"
                    ) ? (
                      <video
                        src={getUrlImage(details.file_main?.post_file_path)}
                        width={400}
                        autoPlay
                      ></video>
                    ) : (
                      <Image
                        preview
                        width="400"
                        src={getUrlImage(details.file_main?.post_file_path)}
                      />
                    )}
                  </div>

                  <hr />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
