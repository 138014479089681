/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useImperativeHandle, useContext } from "react";
import { forwardRef } from "react";
import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact/dropdown";
import VLocation from "components/v-location";
import { OBJECT_TYPE } from "utils/enum";
import CategoryFilterService from "services/category-filters";

const CategoryFilterDetails = ({ isCensorLocation = false }, ref) => {
  const emptyData = {
    object_type: null,
    career_id: null,
    nation_id: null,
    religion_id: null,
    category_field_id: null,
    location: {},
  };
  const {
    locations,
    careers,
    nations,
    religions,
    categoryFields,
    setLocationAuth,
  } = useContext(MasterContext);

  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      return submit();
    },
  }));

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    setLocationAuth(details.location);
    try {
      const name = getName();
      const data = await CategoryFilterService.create({
        body: {
          ...details,
          name,
          isCheck: true,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getName = () => {
    let name = "";
    Object.keys(details).forEach((key) => {
      switch (key) {
        case "object_type":
          if (details[key]) {
            name = name + `${details[key]}, `;
          }
          break;
        case "career_id":
          if (details[key]) {
            const career = careers.find((c) => c._id === details[key]);
            name = name + `${career.name}, `;
          }
          break;
        case "nation_id":
          if (details[key]) {
            const nation = nations.find((c) => c._id === details[key]);
            name = name + `${nation.name}, `;
          }
          break;
        case "religion_id":
          if (details[key]) {
            const religion = religions.find((c) => c._id === details[key]);
            name = name + `${religion.name}, `;
          }
          break;
        case "category_field_id":
          if (details[key]) {
            const field = categoryFields.find((c) => c._id === details[key]);
            name = name + `${field.name}, `;
          }
          break;
        case "location":
          if (details[key]["ward"]) {
            const field = locations.find((c) => c._id === details[key]["ward"]);
            name = name + `${field.name}, `;
          }
          if (details[key]["district"]) {
            const field = locations.find(
              (c) => c._id === details[key]["district"]
            );
            name = name + `${field.name}, `;
          }
          if (details[key]["province"]) {
            const field = locations.find(
              (c) => c._id === details[key]["province"]
            );
            name = name + `${field.name}, `;
          }
          if (details[key]["country"]) {
            const field = locations.find(
              (c) => c._id === details[key]["country"]
            );
            name = name + `${field.name}, `;
          }
          break;
        default:
          break;
      }
    });
    return name;
  };

  return (
    <div className="grid">
      {/* <div className="field col-12">
        <label htmlFor="name">Name</label>
        <InputText
          disabled
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div> */}
      {!isCensorLocation && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Object</label>
            <Dropdown
              showClear
              value={details.object_type}
              options={Object.keys(OBJECT_TYPE).map((k) => ({
                label: k,
                value: OBJECT_TYPE[k],
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select object"
              onChange={(e) => onChange("object_type", e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Career</label>
            <Dropdown
              showClear
              filter
              value={details.career_id}
              options={careers.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChange("career_id", e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Nation</label>
            <Dropdown
              showClear
              filter
              value={details.nation_id}
              options={nations.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChange("nation_id", e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Religion</label>
            <Dropdown
              showClear
              filter
              value={details.religion_id}
              options={religions.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChange("religion_id", e.value)}
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="name">Category Field</label>
            <Dropdown
              filter
              showClear
              value={details.category_field_id}
              options={categoryFields.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChange("category_field_id", e.value)}
            />
          </div>
        </>
      )}

      <div className="field col-12">
        <label htmlFor="name">Address current</label>
        <VLocation
          address={details.location}
          setAddress={(a) => onChange("location", a)}
        />
      </div>
    </div>
  );
};

export default forwardRef(CategoryFilterDetails);
