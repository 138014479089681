export enum RequestParams {
  USERID = "userId",
}

export enum COMMON_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MAIL_TEMPLATE {
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_CODE = "SEND_CODE",
  VERIFY_LOGIN = "VERIFY_LOGIN",
}

export enum ROLE {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum USER_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  BLOCKED = "blocked",
}

export enum USER_GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export enum OBJECT_TYPE {
  USER = "USER",
  UNDER_18 = "UNDER_18",
  CEO = "CEO",
  ORGANIZATION = "ORGANIZATION",
  PAGE = "PAGE",
  GROUP = "GROUP",
  BUSINESS = "BUSINESS",
  COMMUNITY = "COMMUNITY",
}

export enum ACCOUNT_TYPE {
  NORMAL = "normal",
  COUNTRY = "country",
  PROVINCE = "province",
  CAREER = "career",
  NATION = "nation",
  RELIGION = "religion",
}

export enum CAREER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_TYPE {
  MENU = "menu",
  SUBMENU = "submenu",
  TAB = "tab",
}

export enum MENU_TYPE_VIEW {
  LINK = "link",
  INTERNAL = "internal",
  WEBVIEW = "webview",
  SELECT = "select",
}

export enum MENU_ROLE {
  ADMIN = "admin",
  USER = "user",
  CENSOR = "censor",
}

export enum STAR_FEELING_TYPE {
  FEELING = "feeling",
  STAR = "star",
  ACTIVITY = "activity",
  REACTION = "reaction",
}

export enum CATEGORY_POST {
  BACKGROUND = "background",
  FRAME = "frame",
  STICKER = "sticker",
  FEELING = "feeling",
}

export enum PRODUCT_STATUS {
  PENDING = "pending",
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum PRODUCT_TYPE {
  CODE_INTERNAL = "code_internal",
  CODE_EXTERNAL = "code_external",
  STICKER = "sticker",
  FRAME = "frame",
  ACTION_POST = "action_post",
  POST_BACKGROUD = "post_background",
}
