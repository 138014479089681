/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { forwardRef } from "react";

const Details = (props, ref) => {
  const { data } = props;
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  return (
    <div className="grid">
      {details && (
        <div className="col-12">
          <div className="card">
            <div className="grid">
              <div className="field col-12">
                <div>
                  <div>
                    <label htmlFor="content">User: </label>
                    <span>{details?.user.full_name}</span>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="content">Email: </label>
                    <span>{details?.user.email}</span>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="content">IP: </label>
                    <span>{details?.ip}</span>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="content">User Agent: </label>
                    <span>{details?.user_agent}</span>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
