/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PostReportService from "services/posts-reports";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

const emptyData = null;

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  const onChange = (name: string, value: any, target?: string) => {
    let _details = { ...details };
    if (target) {
      _details[`${target}`][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      await PostReportService.update({
        params: {
          id: details._id,
        },
        body: {
          admin_status: details.post.admin_status,
          ...(details.post.admin_status === "disable" && {
            skip_reason: details.skip_reason,
          }),
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="pb-3 flex align-items-center">
            <h4>Post Detail</h4>
          </div>
          <div className="grid">
            {details && (
              <div className="field col-12">
                <div className="grid col-12">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="status">Status: </label>
                    <Dropdown
                      defaultValue={details.post?.admin_status}
                      value={details.post?.admin_status}
                      options={[
                        {
                          label: "ENABLE",
                          value: "enable",
                        },
                        {
                          label: "DISABLE",
                          value: "disable",
                        },
                      ]}
                      onChange={(e) =>
                        onChange("admin_status", e.value, "post")
                      }
                      optionLabel="label"
                      placeholder="Select status"
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="reason">Reason: </label>
                    <InputText
                      id="reason"
                      value={details.skip_reason}
                      onChange={(e) => onChange("skip_reason", e.target.value)}
                      disabled={details.post.admin_status === "enable"}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="order">Title: </label>
                  <span>{details.post.post_title}</span>
                  <hr />
                </div>
                <div>
                  <label htmlFor="order">Content: </label>
                  <span>{details.post.post_content}</span>
                  <hr />
                </div>
                <div>
                  <DataTable value={details.post_reports}>
                    <Column header="Detail" field="report_detail" />
                  </DataTable>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
