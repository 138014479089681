/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import VLocation from "components/v-location";
import { Image } from "primereact/image";
import CensorVerifyService from "services/censor-verify";
import { InputTextarea } from "primereact/inputtextarea";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    status: "pending",
    user: null,
    description: "",
    location: null,
    images: [],
    note: "",
  };

  const optionStatus = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Denied",
      value: "denied",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending") {
      setReadOnly(true);
    }
  }, [data]);

  const onChangeEditor = (e, name) => {
    setDetails((prev) => ({
      ...prev,
      [name]: e.htmlValue,
    }));
  };

  const onChangeDetail = (key, value) => {
    setDetails((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      if (details.status === "denied" && !details.description) {
        return showToast(toast, "warn", "Please enter note!");
      }

      setLoading(true);
      if (data) {
        await CensorVerifyService.update({
          params: {
            id: data._id,
          },
          body: {
            user: details.user._id,
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="email">Email</label>
          <InputText value={details.user?.email} disabled />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Status</label>
          <Dropdown
            value={details.status}
            options={optionStatus}
            onChange={(e) => onDropdownChange(e, "status")}
            optionLabel="label"
            placeholder="Select status"
            disabled={readOnly}
          />
        </div>
        <div className="field col-12">
          <VLocation
            address={details.location}
            setAddress={() => {}}
            isDisabled={true}
          />
        </div>
        <div className="col-12 field">
          <label>Images</label>
          {details.images.map((at, index) => (
            <div key={index}>
              <Image
                src={getUrlImage(at.url)}
                width="200"
                height="auto"
                preview
              />
              <InputText value={at.link} disabled />
            </div>
          ))}
        </div>

        <div className="field col-12">
          <label htmlFor="description">Description</label>
          <InputTextarea
            value={details.description}
            onChange={(e) => onChangeDetail("description", e.target.value)}
          />
        </div>
        <div className="field col-12 mt-5">
          <label htmlFor="note">Note</label>
          <Editor
            id="note"
            className="h-12rem"
            value={details.note}
            onTextChange={(e) => onChangeEditor(e, "note")}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(Details);
