/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import MenuService from "services/menus";
import { MENU_STATUS, MENU_TYPE, MENU_TYPE_VIEW } from "utils/enum";
import { Dropdown, InputNumber } from "primereact";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import VUpload from "components/v-upload";
import VDialog from "components/v-dialog";
import IconBar from "components/v-icon-bar";
import { ICONS, selectEmpty } from "utils/constant";
import { Checkbox } from "primereact/checkbox";
import CategoryFilterDetails from "components/v-filter";
import { Panel } from "primereact/panel";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    treeMenus,
    loadTreeMenus,
    menusCategory,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    name: "",
    description: "",
    key: "",
    type: MENU_TYPE.MENU,
    url: "",
    type_view: MENU_TYPE_VIEW.INTERNAL,
    icon: "",
    img_icon: "",
    parent_id: null,
    category: "",
    position: "",
    order: 0,
    width: "",
    height: "",
    width_scrollbar: "",
    required_token: false,
    status: MENU_STATUS.ENABLE,
    category_filter_id: null,
  };

  const [details, setDetails] = useState(emptyData);
  const refDialogDetail = useRef(null);
  const refDetailCategory = useRef(null);
  const icons = ICONS.KEYS.map((i) => ICONS.PREFIX + i + ICONS.SIZE);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value === "-1" ? null : value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await MenuService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await MenuService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      loadTreeMenus();
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  //CREATE OR UPDATE
  const openNew = () => {
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSelectIcon = (e) => {
    setDetails({ ...details, icon: e });
    onCancelDialogDetail();
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Menu Category</label>
        {/* <InputText
          id="position"
          value={details.position}
          onChange={(e) => onChange("position", e.target.value)}
          required
        /> */}
        <Dropdown
          filter
          value={details.position}
          options={menusCategory}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("position", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key (Language)</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Description</label>
        <InputText
          id="description"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(MENU_TYPE).map((k) => ({
            label: k,
            value: MENU_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type View</label>
        <Dropdown
          value={details.type_view}
          options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
            label: k,
            value: MENU_TYPE_VIEW[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type_view", e.value)}
        />
      </div>
      {details.type_view === MENU_TYPE_VIEW.WEBVIEW && (
        <>
          <div className="field col-12">
            <label htmlFor="key">Required</label>
            <div className="ml-2">
              <Checkbox
                onChange={(e) => onChange("required_token", e?.checked)}
                checked={details.required_token}
              ></Checkbox>
            </div>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Width</label>
            <InputText
              id="description"
              value={details.width}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("width", e.target.value)}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Height</label>
            <InputText
              id="description"
              value={details.height}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("height", e.target.value)}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Width ScrollBar</label>
            <InputText
              id="description"
              value={details.width_scrollbar}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("width_scrollbar", e.target.value)}
              required
            />
          </div>
        </>
      )}
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
          disabled
        />
      </div>

      {/* <div className="field col-12 md:col-6">
        <label htmlFor="name">Icon</label>
        <InputText
          id="icon"
          value={details.icon}
          onChange={(e) => onChange("icon", e.target.value)}
          required
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Icon</label>
        <div>
          <div>
            {details.icon ? (
              <Button icon={details.icon || ""} disabled visible />
            ) : (
              <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
            )}{" "}
            &nbsp;&nbsp;
            <>
              {!details.icon ? (
                <Button icon="pi pi-plus" onClick={openNew} />
              ) : (
                <Button
                  icon="pi pi-times"
                  className="p-button-sm p-button-danger"
                  aria-label="Delete"
                  style={{
                    height: "38px",
                    marginLeft: "3px",
                  }}
                  onClick={(_) => {
                    if (details.icon) onChange("icon", null);
                  }}
                />
              )}
              <VUpload
                urlFile={details.img_icon}
                setUrlFile={(file) => onChange("img_icon", file)}
              />
            </>
          </div>
        </div>
      </div>

      <VDialog ref={refDialogDetail} header="Select icon">
        <IconBar selected={onSelectIcon} icons={icons}></IconBar>
      </VDialog>
      {/* <div className="field col-12 md:col-12">
        <label htmlFor="description">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={menus.map((m) => ({
            label: m.name,
            value: m._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("parent_id", e.value)}
          showClear
          filter
        />
      </div> */}
      <div className="field col-12 md:col-12">
        <label htmlFor="description">Parent</label>
        <TreeSelect
          placeholder="Select Parent Menu"
          inputId="treeselect"
          value={details.parent_id}
          onChange={(e) => onChange("parent_id", e.value)}
          options={[selectEmpty, ...treeMenus]}
          filter
        />
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="name">URL</label>
        <InputText
          id="url"
          value={details.url}
          onChange={(e) => onChange("url", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(MENU_STATUS).map((k) => ({
            label: k,
            value: MENU_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails
                  ref={refDetailCategory}
                  isCensorLocation
                />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
